import React from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";

const divParaStyle = {
  marginTop: '70px'
}

const Konzept = () => {
  return (
      <Layout>
        <Seo title="Konzept der Naturspielgruppe Wakiti in Uster" />
        <div className="content">
          <h1>Konzept</h1>
            <div style={divParaStyle}>
              <h3>Ziel der Spielgruppe</h3>
              <p>Besonders Kinder blühen in der Natur sichtbar auf. Sie entdecken die Umwelt mit all ihren Sinnen und können ihrer Neugierde folgen. Durch das gemeinsame Spiel im Freien werden sowohl die Fantasie und Kreativität als auch die emotionalen und sozialen Kompetenzen des Kindes gefördert. Die Kinder haben die Möglichkeit, selbstbestimmt zu lernen und machen existenzielle Erfahrungen. Das Angebot in der Spielgruppe richtet sich nach dem Entwicklungsstand der Kinder und wir lernen spielerisch. Die Kinder lernen, ihren Platz in einer Gruppe Gleichaltriger zu finden, sich zu behaupten, Rücksicht zu nehmen, sich und die Anderen mit Stärken und Schwächen zu akzeptieren. Einander zu helfen, aufeinander zu hören, aber auch zu streiten und wieder Frieden zu schliessen, gehört ebenfalls zur Spielgruppe.</p>
              <p>Auf unserem Hof pflanzen und ernten wir gemeinsam Gemüse, Früchte und Beeren. Die Kinder haben die Gelegenheit, in diesen Prozess miteinbezogen zu werden. Zudem leben Zwergziegen auf dem Hof. Durch den Umgang mit den Tieren lernen sie Körpersprache besser zu deuten und eigene Wünsche zugunsten eines anderen Lebewesens zurückzustellen.</p>
            </div>
            <div style={divParaStyle}>
              <h3>Unsere Leitsätze</h3>
              <p>- Das Kind steht im Mittelpunkt. Die Spielgruppe fördert Selbstkompetenz, Eigeninitiative und Entscheidungsfreude.</p>
              <p>- Das Kind wählt den Zeitpunkt. Die Spielgruppe fördert individuelle, ganzheitliche und selbstbildende Lernprozesse.</p>
              <p>- Das Kind vertraut in seine Fähigkeiten. Die Spielgruppe fördert die Fantasie und die Experimentierfreude.</p>
              <p>- Das Kind begreift durch Erfahrung. Die Spielgruppe fördert die Wahrnehmung sowie die intellektuelle, motorische und psychosoziale Entwicklung des Kindes.</p>
              <p>- Das Kind lernt mit und von anderen Kindern. Die Spielgruppe fördert das soziale Lernen und bietet ein Übungsfeld für die Integration und das Leben in der Gemeinschaft.</p>
              <p>- Das Kind entdeckt seine Stärken. Die Spielgruppe fördert den Selbstlernprozess, emotionale Kompetenzen, die Selbstwahrnehmung und die Persönlichkeitsentwicklung.</p>
              <p>- Das Kind fühlt sich zugehörig. Die Spielgruppe fördert die Integration, Vielfalt, Toleranz und das Verständnis.</p>
              <p>- Das Kind teilt sich mit. Die Spielgruppe fördert die Sprachkompetenz.</p>
              <p>- Das Kind vertraut auf Erwachsene. Die Spielgruppenleiterin erkennt die Bedürfnisse im Ablösungsprozess und begleitet das Kind geduldig und verständnisvoll.</p>
            </div>
            <div style={divParaStyle}>
              <h3>Was uns am Herzen liegt</h3>
              <p>Wir legen grossen Wert darauf, dass die Kinder ihren Interessen nachgehen können und ihnen die Zeit gewährt wird, welche sie dazu brauchen. Jedes Kind entwickelt sich auf seine eigene Weise. Wir begleiten sie auf diesem Weg und unterstützen sie, wo wir können. Die Zeit mit den Kindern gestalten wir herzlich und einfühlsam, alle werden als einzigartige Persönlichkeit wahr- und ernstgenommen. In der heutigen Zeit wird von den Kindern bereits viel verlangt und erwartet. Die Spielgruppe bietet Platz, wo Kinder noch Kinder sein dürfen.</p>
            </div>
        </div>
      </Layout>
  )
}

export default Konzept;